import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    children: [
      {
        path: '/homes',
        name: 'homes',
        component: () => import('../components/nav/HomeItem.vue')
      },
      {
        path: '/mission',
        name: 'mission',
        component: () => import('../components/nav/MissionItem.vue')
      },
      {
        path: '/mode',
        name: 'mode',
        component: () => import('../components/nav/PublishMode.vue')
      },
      // {
      //   path: '/Editem',
      //   name: 'Editem',
      //   component: () => import('../components/nav/EditorsItem.vue')
      // },
      {
        path: '/acess',
        name: 'acess',
        component: () => import('../components/nav/OpenAcess.vue')
      },
      {
        path: '/apc',
        name: 'apc',
        component: () => import('../components/nav/ApcItem.vue')
      },
      {
        path: '/issue',
        name: 'issue',
        component: () => import('../components/nav/SpecialItem.vue')
      },
      {
        path: '/journals',
        name: 'journals',
        component: () => import('../components/nav/JournalItem.vue')
      },
      {
        path: '/books',
        name: 'books',
        component: () => import('../components/nav/BooksItem.vue')
      },
      {
        path: '/publishing',
        name: 'publishing',
        component: () => import('../components/nav/PublishIng.vue')
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../components/nav/ContactUs.vue')
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('../components/nav/SearchItem.vue')
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('../components/nav/NewsItem.vue')
      },
      {
        path: '/careers',
        name: 'careers',
        component: () => import('../components/foot/CareerItem.vue')
      },
      {
        path: '/print',
        name: 'print',
        component: () => import('../components/foot/PrintEdit.vue')
      },
      {
        path: '/authors',
        name: 'authors',
        component: resolve => require(['../components/foot/AuthorItem.vue'], resolve)
      },
      {
        path: '/publish',
        name: 'publish',
        component: resolve => require(['../components/foot/PublishItem.vue'], resolve)
      },
      
      {
        path: '/Editem',
        name: 'Editem',
        component: () => import('../components/nav/EditorsItem.vue')
      },
      {
        path: '/review',
        name: 'review',
        component: () => import('../components/foot/ReviewItem.vue')
      },
      {
        path: '/fraud',
        name: 'fraud',
        component: () => import('../components/foot/FraudItem.vue')
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../components/foot/PrivacyItem.vue')
      },
      {
        path: '/detail',
        name: 'detail',
        component: () => import('../views/NewDetail.vue')
      },
      {
        path: '/bookdetail',
        name: 'bookdetail',
        component: () => import('../views/BookDetail.vue')
      },
      

    ]
  },
  {
    path: '/',
    redirect: '/homes'
  },
  {
    path: '*',
    name: 'no',
    component: () => import('../views/NoView.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
